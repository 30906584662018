import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { EncodeQrossQRData, NoQrossDataView, QrossQRDisplay } from './QrossLogic';
import ReactMarkdown from 'react-markdown'

const PUBLIC_URL = process.env.PUBLIC_URL;


function useQuery() {
  return new URLSearchParams(useLocation().search);
}
export default function Qross({ style }: { style?: React.CSSProperties }) {
  // const query = useQuery();
  const ref = React.useRef<HTMLDivElement>(null);
  const [width, setWidth] = useState(512);
  useEffect(() => {
    const gotwidth = ref.current ? ref.current.offsetWidth : 512;
    setWidth(gotwidth);
  }, [ref.current]);
  const topDetailStyle = useMemo(() => {
    return {
      display: 'flex',
      flexDirection: width > 512 ? 'row' : 'column',
      padding: 8,
      alignItems: 'flex-start'
    } as React.CSSProperties;
  }, [width]);

  const [qrText, setQRText] = useState<string>();


  const onQRTextFieldChange = useCallback((e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setQRText(e.target.value);
  }, []);


  const qrossData = useMemo(() => {
    return qrText ? EncodeQrossQRData(qrText) : undefined;
  }, [qrText]);

  // const appRedirect = query.get('toapp');
  // if (appRedirect) {
  //   const pid = query.get('pid');
  //   const mode = query.get('mode')
  //   if (mode == 'friendadd') {
  //     return <LongevityAppRedirectorRender appUrl={`longevity://--/friendadd?id=${pid}`} />
  //   }
  // }


  return <div style={localStyles.thisPage} ref={ref}>
    <div style={localStyles.thisBox}>
      <img src={`${PUBLIC_URL}/images/qrosslogo.png`} alt="Qross Logo" style={{ width: 114, height: 114, borderRadius: 10, margin: 10 }} />
      <div style={{ display: 'flex', flex: 1, flexDirection: 'column', alignItems: 'flex-end', justifyContent: 'flex-end' }}>
        <h1 style={{ fontSize: 32 }}>Qross</h1>
        <p style={{ fontSize: 16, marginTop: -2, alignSelf: 'flex-end' }}>Transfer your data across with QR</p>
      </div>
    </div>
    <div style={{ height: 1, backgroundColor: '#000' }} />
    <div id='top-detail' style={topDetailStyle}>
      <div id='qr-generator' style={{ display: 'flex', flexDirection: 'column', padding: 6, backgroundColor: '#ffba1f', borderRadius: 10, boxShadow: '2px 8px 6px #88888888' }}>
        <p style={{ fontSize: 18, fontWeight: 'bold' }}>Create QR</p>
        <textarea value={qrText} placeholder='Enter text to send here' onChange={onQRTextFieldChange} style={{ minHeight: 80, marginBottom: 4 }} />
        {
          qrossData ?
            <QrossQRDisplay data={qrossData} />
            :
            <NoQrossDataView />
        }
        <p style={{ fontSize: 12, fontWeight: 'bold' }}>Scan this QR with QRoss on your other device!</p>
      </div>
      <div id='text-in-top-detail' style={{ display: 'flex', flexDirection: 'column', textAlign: 'left', padding: 8 }}>
        <ReactMarkdown>
          {detailsMarkdown1}
        </ReactMarkdown>
      </div>
    </div>
    <div style={{ textAlign: 'left', padding: 8 }}>
      <ReactMarkdown>
        {detailsMarkdown2}
      </ReactMarkdown>
    </div>
    <br />
    <br />
    <a href={`https://apps.apple.com/app/id1566870419`} target="_blank" rel="noopener noreferrer" style={{ color: 'green', margin: 10 }}>
      Download From The App Store!<br /><br />
      <img alt="Download on the App Store" src={`${PUBLIC_URL}/available-on-the-app-store.svg`} height={50} />
    </a>
    <a href={`https://play.google.com/store/apps/details?id=swoft.qross`} target="_blank" rel="noopener noreferrer" style={{ color: 'green', margin: 10 }}>
      Get it Google Play<br /><br />
      <img alt="Download from Google Play" src={`${PUBLIC_URL}/Google_Play_Store_badge_EN.svg`} height={50} />
    </a>
    <div style={localStyles.privacyBox}>
      <h4>Privacy policy</h4>
      <p>- The app itself stores none of your data</p>
    </div>
    <br />
    <br />
  </div>
}

// function LongevityAppRedirectorRender(props: {appUrl: string }) {
//   const { appUrl } = props;
//   useEffect(() => {
//     const now = Date.now();
//     window.location.href = appUrl;
//     const tmo = setTimeout(function () {
//       if (Date.now() - now > 100) return;
//       // Redirect to app store if could not go to app.
//       window.location.href = "https://apps.apple.com/app/longevity-4-in-a-row/id951000673";
//     }, 100);
//     return () => {
//       clearTimeout(tmo);
//     }
//   }, []);
//   return <></>;
// }

const detailsMarkdown1 =
  `
## Want to copy text across devices on different platforms (iOS/Android)?

If you're a person working with text or small images, across multiple devices, you'd know this scenario. Usually you might resort to copying that particular text you want to transfer, pasting it into your messaging app of choice, and then copy it from that app on your destination device.

But is that really the optimal way to do things?

QRoss is born from that particular scenario, causing me, personally, annoyance. And it also ruins the working mood.

This app aims to make that particular step in your workflow as short as possible. Just copy the text you want to copy as usual, open the app, the app launches and instantly displays the text you copied as a QR code, you open the same app on your destination device, point it at the QR code, and the text is instantly copied into your clipboard, ready to be pasted.

Whatever your workflow, whether it be addresses, plain text documents, memos. This might be useful for you. I know it is for me, anyway :)
`
const detailsMarkdown2 =
  `
### What is this for?
- You want to copy some text to use on another mobile device (maybe yours, maybe a friend's)
- You want to send some text
- Or just for fun, you want to see if QR can be used to send images.

### How to use
- Select the type of data you want to send on the top of the screen.
  - **"clipboard"** _: This sends the last text you copied._
    - This is the default, and is likely the most common use for this app.
    - Check that the text preview is the same as text you just copied.
    - A QR code (or animated QR) should be displaying

  - **"type"** _: This is for text that you just type on the spot and want to send._
    - Type the text you want to send in the text field directly below the selector.
    - A QR should be generated

  - **"image"** _: This sends an image you pick. However, images are compressed to 40000 pixels max. This will be blurry_
    - Select an image, then an animated QR should be generated
- Open QRoss on the receiving device
- Scan the QR code being shown
- Wait for the scan to finish
- Done!

&nbsp;
#### "Copy received text to clipboard"
- By default, this is turned on. So while you're working it'll be a seamless experience of scanning and having the data ready to be pasted somewhere else immediately.
  - If this is turned off, you'll be prompted whether to copy the text or to dismiss it.
`



interface StyleSheet {
  [key: string]: React.CSSProperties;
}
const localStyles: StyleSheet = {
  thisPage: {
    backgroundColor: '#a6d2ff',
    display: 'flex',
    flexDirection: 'column'
  },
  thisBox: {
    margin: 8,
    padding: 8,
    borderRadius: 10,
    backgroundColor: '#454596',
    display: 'flex',
    flexDirection: 'row',
    alignSelf: 'center',
    borderWidth: 1,
    borderColor: '#EEEEEE',
    color: '#fffced'
  },
  privacyBox: {
    margin: 8,
    padding: 8,
    borderRadius: 10,
    backgroundColor: '#666',
    display: 'flex',
    alignSelf: 'center',
    borderWidth: 1,
    borderColor: '#EEEEEE',
    flexDirection: 'column',
    color: '#EEE',
    alignItems: 'flex-start'
  }
}
